"use client";

import { useRouter } from "next/router";
import type {
  Dispatch,
  KeyboardEvent,
  KeyboardEventHandler,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
} from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { Hit, SearchResults, SearchState } from "react-instantsearch-core";
import {
  Configure,
  connectHits,
  connectSearchBox,
  connectStateResults,
  Highlight,
  Index,
  InstantSearch,
} from "react-instantsearch-dom";
import { useClickAway } from "react-use";
import title from "title";
import type { DeepReadonly } from "ts-essentials";

import { searchClient } from "../../../../home/lib/algoliasearch";
import type {
  ReferenceSearchSchema,
  TypeSearchSchema,
} from "../../../../home/lib/types";

interface ReferenceHitProperties {
  hits: Hit<TypeSearchSchema>[];
  onClickCallback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customRef?: React.MutableRefObject<Map<unknown, unknown>>;
}
interface GuideHitProperties {
  hits: Hit<ReferenceSearchSchema>[];
  onClickCallback?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  customRef?: React.MutableRefObject<Map<unknown, unknown>>;
}

const SearchBox = (
  props: DeepReadonly<{
    currentRefinement: string;
    refine: (argument: string) => void;
    viewResultCallback: (argument: boolean) => void;
    keyDownCallback: KeyboardEventHandler<HTMLInputElement>;
    inputReference: React.RefObject<HTMLInputElement>;
  }>,
) => {
  const {
    currentRefinement,
    viewResultCallback,
    refine,
    inputReference,
    keyDownCallback,
  } = props;

  useEffect(() => {
    if (currentRefinement === "") {
      viewResultCallback(false);
    } else {
      viewResultCallback(true);
    }

    return () => {
      viewResultCallback(false);
    };
  }, [currentRefinement, viewResultCallback]);

  return (
    <form action="" noValidate role="search">
      <label className="relative block w-full sm:w-2/3">
        <span className="sr-only">Search</span>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <img alt="" src="/img/search-icon.svg" />
        </div>
        <input
          className="lg:w-112 block h-10 w-full rounded-full border border-transparent bg-white pl-10 pr-2 text-sm hover:border-black focus:border-black focus:outline-none focus:ring-1 focus:ring-black"
          data-testid="input::document-search"
          onChange={(event) => {
            refine(event.currentTarget.value);
          }}
          onKeyDown={keyDownCallback}
          placeholder="Search Documentation"
          ref={inputReference as never}
          value={currentRefinement}
        />
      </label>
    </form>
  );
};

const ResultsComponent = ({
  children,
  setResultsCallback,
  searchResults,
}: Readonly<
  PropsWithChildren<{
    setResultsCallback: Dispatch<SetStateAction<number>>;
    searchResults?: SearchResults;
  }>
>): JSX.Element => {
  if (searchResults) {
    setResultsCallback(searchResults.nbHits);
  }
  if (searchResults && searchResults.nbHits !== 0) {
    return <>{children}</>;
  }
  return <></>;
};

const GuideSearchHitComponent = ({
  hits,
  onClickCallback,
  customRef,
}: GuideHitProperties): JSX.Element => (
  <ol>
    {hits.map((guidePage: Readonly<ReferenceSearchSchema>) => (
      <button
        className="hover:bg-ash focus:bg-ash rounded-rounded flex w-full cursor-pointer items-center space-x-2 p-2.5 text-sm focus:outline-none"
        data-href={guidePage.url}
        data-testid="search-result::document"
        key={guidePage.objectID}
        onClick={onClickCallback}
        ref={(reference_) => {
          customRef?.current.set(guidePage.objectID, reference_);
        }}
        type="button"
      >
        <div className="flex flex-col space-y-0.5 truncate text-left">
          <div className="bg-bone rounded-button text-xxs flex w-max items-center p-1 px-2 font-medium">
            {guidePage.type === 2 ? "Documentation" : "Documentation: Topic"}
          </div>
          {guidePage.title && (
            <Highlight
              attribute="title"
              hit={guidePage}
              tagName="highlight-reference"
            />
          )}
          {guidePage.body !== undefined && (
            <div className="truncate text-xs">
              <Highlight
                attribute="body"
                hit={guidePage}
                tagName="highlight-reference"
              />
            </div>
          )}
        </div>
      </button>
    ))}
  </ol>
);

const GraphqlSearchHitComponent = ({
  hits,
  onClickCallback,
  customRef,
}: ReferenceHitProperties): JSX.Element => (
  <ol>
    {hits.map((typeSchema: Readonly<TypeSearchSchema>) => (
      <button
        className="hover:bg-ash focus:bg-ash rounded-rounded flex w-full cursor-pointer items-center space-x-2 p-2.5 text-sm focus:outline-none"
        data-href={`/docs/reference/${typeSchema.section}/${typeSchema.name}`}
        data-testid="search-result::document"
        key={typeSchema.objectID}
        onClick={onClickCallback}
        ref={(reference_) => {
          customRef?.current.set(typeSchema.objectID, reference_);
        }}
        type="button"
      >
        <div className="flex flex-col space-y-0.5 truncate text-left">
          <div className="bg-bone rounded-button text-xxs flex w-max items-center p-1 px-2 font-medium">
            Reference: {title((typeSchema.section || "").replaceAll("_", " "))}
          </div>{" "}
          {typeSchema.name ? (
            <Highlight
              attribute="name"
              hit={typeSchema}
              tagName="highlight-reference"
            />
          ) : undefined}
          {typeSchema.description ?? "" ? (
            <div className="truncate text-xs">
              <Highlight
                attribute="description"
                hit={typeSchema}
                tagName="highlight-reference"
              />
            </div>
          ) : undefined}
        </div>
      </button>
    ))}
  </ol>
);

const CustomHitsBoxGraph = connectHits<
  ReferenceHitProperties,
  TypeSearchSchema
>(GraphqlSearchHitComponent);
const CustomHitsBoxDocuments = connectHits<
  GuideHitProperties,
  ReferenceSearchSchema
>(GuideSearchHitComponent);
const Results = connectStateResults(ResultsComponent);
const CustomSearchBox = connectSearchBox(SearchBox);

export const DocumentSearchContainer = (): JSX.Element => {
  const router = useRouter();
  const [viewResults, setViewResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [documentResults, setDocumentResults] = useState(0);
  const [referenceResults, setReferenceResults] = useState(0);
  const [resultIndex, setResultIndex] = useState(0);
  const inputReference = useRef<HTMLInputElement>(null);
  const hitReferences = useRef(new Map());
  useEffect(() => {
    // Set timeout to capture lifecycle delay in mapping refs
    setTimeout(() => {
      const values: (HTMLButtonElement | undefined)[] = Array.from(
        hitReferences.current.values(),
      );
      values.forEach((hitObject) => {
        hitObject?.classList.remove("bg-ash");
      });

      const firstElement = values[resultIndex];
      if (firstElement !== undefined) {
        firstElement?.classList.add("bg-ash");
      }
    }, 10);
  }, [documentResults, referenceResults, resultIndex]);

  const totalResults = documentResults + referenceResults;

  const handleStateChange = useCallback(
    (searchState: DeepReadonly<SearchState>) => {
      // Reset reference map on keystroke
      // eslint-disable-next-line fp/no-mutation
      hitReferences.current = new Map();
      setResultIndex(0);
      setSearchQuery(searchState.query ?? "");
    },
    [],
  );

  const searchState: SearchState = {
    query: searchQuery,
  };

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>): void => {
      event.preventDefault();
      const { href } = event.currentTarget.dataset;
      void router.push(href ?? "#");
      setSearchQuery("");
      setViewResults(false);
    },
    [router],
  );

  useClickAway(inputReference, (event) => {
    const path = event.composedPath() as Element[];
    if (
      !path.some(
        (element) => element.id === "search" || element.id === "dropdown",
      )
    ) {
      setSearchQuery("");
      setViewResults(false);
    }
  });

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setResultIndex(
        resultIndex + 1 >= Array.from(hitReferences.current.values()).length
          ? resultIndex
          : resultIndex + 1,
      );
    }
    if (event.key === "ArrowUp") {
      event.preventDefault();
      setResultIndex(resultIndex - 1 < 0 ? 0 : resultIndex - 1);
    }
    if (event.key === "Enter") {
      event.preventDefault();
      const values: (HTMLButtonElement | undefined)[] = Array.from(
        hitReferences.current.values(),
      );
      const href = values[resultIndex]?.getAttribute("data-href");
      if (typeof href === "string") {
        void router.push(href);
        setSearchQuery("");
        setViewResults(false);
      }
    }
  };

  useEffect(() => {
    function handleKeyDown(event: { metaKey: boolean; key: string }) {
      if (event.metaKey && event.key === "k") {
        setViewResults(true);
        inputReference.current?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <InstantSearch
      indexName="DEV_DOCS"
      onSearchStateChange={handleStateChange}
      searchClient={searchClient}
      searchState={searchState}
    >
      <div className="group relative w-full lg:w-2/5 xl:w-3/5">
        <CustomSearchBox
          inputReference={inputReference as never}
          keyDownCallback={handleKeyPress}
          viewResultCallback={setViewResults}
        />
        {viewResults ? (
          <div
            className="rounded-rounded ring-ash fixed left-2 -ml-2.5 mt-2 w-full bg-white shadow-lg ring-1 sm:absolute sm:w-5/6"
            id="dropdown"
          >
            <div className="space-y-0.5 p-2">
              <Index indexName="DEV_DOCS">
                <Results setResultsCallback={setDocumentResults}>
                  <CustomHitsBoxDocuments
                    customRef={hitReferences}
                    onClickCallback={handleClick}
                  />
                </Results>
                <Configure hitsPerPage={3} />
              </Index>

              <Index indexName="DEV_GRAPHQL_REFERENCE">
                <Results setResultsCallback={setReferenceResults}>
                  <CustomHitsBoxGraph
                    customRef={hitReferences}
                    onClickCallback={handleClick}
                  />
                </Results>
                <Configure hitsPerPage={3} />
              </Index>
              {totalResults === 0 && (
                <div
                  className="text-xs text-gray-500"
                  data-testid="search-result::no-results"
                >
                  No results have been found for{" "}
                  <span className="bg-bone">{searchState.query}</span>.
                </div>
              )}
            </div>
          </div>
        ) : undefined}
      </div>
    </InstantSearch>
  );
};
